.buttons {
  margin: 10%;
  text-align: center;
}
* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}


/* Scrollbar Styling */
::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background-color: #FFFFFF;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #EFEFEF; 
}
.btn-hover:focus {
    outline: none;
}

#navbar-toggle {
  position: relative;
  right: 0;
  height: 0;
  background-color: #fff;
  animation: toggle 0.7s ease forwards;
  z-index: 2;
}

@keyframes toggle {
  0% {
    right: -400px;
  }
  100% {
    right: 0;
  }
}

.btn-hover {
  width: 148px;
  font-size: 18px;
  font-weight: 400;
  color: #fff;
  cursor: pointer;
  height: 40px;
  text-align: center;
  border: none;
  background-size: 300% 100%;

  border-radius:100px;
  moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.btncheckout-hover {
  width: 150px;
  font-size: 18px;
  font-weight: 400;
  color: #fff;
  cursor: pointer;
  height: 45px;
  text-align: center;
  border: none;
  background-size: 300% 100%;

 
  /* moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out; */
}

.btn-hover:hover {
  background-position: 100% 0;
  moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.btn-hover.color-5 {
  background-image: linear-gradient(
    to right,
    #022d24,
    #146c60,
    #146c60,
    #146c60
  );
}
.save {
  background-image: linear-gradient(
    to right,
    #022d24,
    #146c60,
    #146c60,
    #146c60
  );
}
.btncheckout-hover:hover {
  background-position: 100% 0;
  moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.btncheckout-hover.color-5 {
  background-image: linear-gradient(
    to right,
    #022d24,
    #146c60,
    #146c60,
    #146c60
  );
}
.red-line-through {
  text-decoration: line-through;
  color: rgb(255, 129, 129);
}
